import React from "react";
import { motion } from "framer-motion";
import { SlideLeft, SlideUp } from "../../animation/animate";
import samPortfolio from "../../assets/houseryImages/samPortfolio.png";
import jennaPortfolio from "../../assets/houseryImages/jennaPortfolio.png";
import codyPortfolio from "../../assets/houseryImages/codyPortfolio.png";

const TestimonialData = [
  {
    id: 1,
    name: "Sam",
    designation: "Senior Designer",
    img: samPortfolio,
    para1:
      "With over 5 years of experience in the interior design industry, Sam is a seasoned professional who brings a blend of creativity, technical expertise, and a keen eye for detail to every project.",
    para2:
      "Sam is a mentor to junior designers and fosters a positive, enthusiastic spirit on every project she leads. She believes that with good communication, any collaborative effort can be successful.",
    para3:
      "Previously, Sam worked as a Healthcare IT Project Manager at hospital systems all over the world. Outside of work, Sam enjoys reading, painting, and exploring local restaurants with her husband, Cody.",
    delay: 0.2,
  },
  {
    id: 2,
    name: "Jenna",
    designation: "Architecturl Consultant",
    img: jennaPortfolio,
    para1:
      "Jenna is completing her masters program at the Milwaukee school of Architecture after graduating with a degree in Business Management from the Tippie College of Business.",
    para2:
      "She boasts strong architectural knowledge with proficiency in programs like AutoCAD and Rhino, allowing her to create precise and detailed visual representations of design concepts. Her business background also lends well to financial planning for projects big or small.",
    para3: "Jenna loves puzzles of all kinds and is and avid reader.",
    delay: 0.4,
  },
  {
    id: 3,
    name: "Cody",
    designation: "Business Manager",
    img: codyPortfolio,
    para1:
      "With a degree in Business Management and years of Marine Corps Leadership, Cody ensures that our business runs like a well-oiled machine, allowing our designers to focus on what they do best.",
    para2:
      "His role involves everything from managing schedules to liaising with clients and suppliers, maintaining project records and overseeing financial operations.",
    para3:
      "Cody loves cooking and experimenting with new recipe ideas both in the kitchen and on the grill. He loves to play video games while snuggling with his cats and wife, Sam.",
    delay: 0.6,
  },
];
const Testimonial = () => {
  return (
    <div className="py-14">
      {/* heading title */}
      <div className="space-y-4 text-center max-w-[550px] mx-auto mb-8">
        <motion.h1
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 1.5 }}
          variants={SlideUp(0.4)}
          className="text-4xl font-bold font-serif"
        >
          Meet the Team
        </motion.h1>
        <motion.p
          variants={SlideUp(0.4)}
          viewport={{ once: true }}
          initial="initial"
          whileInView="animate"
          className="text-gray-500 text-sm max-w-[350px] mx-auto"
        >
          Bring your dream home to life with one-on-one design help & hand
          picked products
        </motion.p>
      </div>
      {/* tesitomonial cards */}
      <div className="bg-black p-12">
        <div className="container grid grid-cols-1 md:grid-cols-3 gap-6">
          {TestimonialData.map((card) => {
            return (
              <motion.div
                variants={SlideLeft(card.delay)}
                initial="initial"
                whileInView="animate"
                viewport={{ once: true }}
                key={card.id}
                className="border-[1px] border-gray-500 px-5 py-10 text-white group hover:bg-white duration-300"
              >
                {/* Upper section */}
                <div className="flex flex-row items-center gap-3 ">
                  <img
                    src={card.img}
                    alt=""
                    className="w-[60px] aspect-square rounded-full"
                  />
                  <div>
                    <p className="text-sm font-bold group-hover:text-black">
                      {card.name}
                    </p>
                    <p className="text-gray-400 text-xs group-hover:text-black">
                      {card.designation}
                    </p>
                  </div>
                </div>
                {/* Bottom section */}
                <div className="mt-5 border-t-2 border-gray-500/40 pt-5">
                  <p className="text-sm text-gray-300 group-hover:text-black duration-300">
                    {card.para1}
                  </p>
                  <br></br>
                  <p className="text-sm text-gray-300 group-hover:text-black duration-300">
                    {card.para2}
                  </p>
                  <br></br>
                  <p className="text-sm text-gray-300 group-hover:text-black duration-300">
                    {card.para3}
                  </p>
                </div>
              </motion.div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
